<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>


<v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ form_title }}</span>
        </v-card-title>
        <v-card-text>
            <v-row style="margin-top:0px">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Nom Complet *"
                  required
                  v-model=user.full_name
                ></v-text-field>
                
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="E-mail *"
                  required
                  v-model=user.email
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="Téléphone *"
                  required value="+509-"
                  v-model=user.phone
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="Nom d'Utilisateur *"
                  required
                  v-model=user.username
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="Mot de Passe *"
                  type="password"
                  required
                  v-model=user.password
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                sm="6"
              >
                <v-select
                  :items="roles"
                  item-value=id
                  item-text=name
                  label="Accès *"
                  required
                  v-model=user.role_id
                ></v-select>
              </v-col>
              <v-col
                cols="6"
                sm="6"
              >
                <v-select
                  :items="status"
                  item-value=id
                  item-text=name
                  label="Statut *"
                  required
                  v-model=user.status
                ></v-select>
              </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="close_modal_button"
            text
            @click="close"
          >
            Fermer
          </v-btn>
          <v-btn
            color="blue"
            text
            class = "confirm_modal_button"
            v-on:click=save
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
<PreloaderComponent :enable=enable></PreloaderComponent>

<v-container fluid class="settingscontainer" v-if=enable>
  <v-card
  elevation="2" class="text-right"
>
  <v-card>
  <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher"
        single-line
        hide-details
        class="tablesearch"
      ></v-text-field>
    </v-card-title>
  <v-data-table
    :headers="headers"
    :items="rows"
    :items-per-page="10"
    class="elevation-1"
    :search="search"
  >
    <template v-slot:item.status="{ item }">
      <v-chip
        :class="getStatusColor(item.status)"
      >
        {{ getStatusLabel(item.status) }}
      </v-chip>
    </template>
    <template v-slot:item.role.name="{ item }">
      <v-chip class=uppercase
      >
        {{ getRoleLabel(item) }}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2 edit_button"
        @click="edit(item)"
      >
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</v-card>

</v-card>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'

export default {
  name: 'SettingsView',
  methods: {
    load: function(){
      var self = this;
      this.$axios.get('/users?include=role&orderasc=full_name')
        .then(function (response) {
          self.rows = response.data
          self.enable = true          
        })
    },
    getStatusColor: function(status){
        var color = "class_red"
        if(status){
          color = "class_green"
        }
        return color
      },
      getStatusLabel: function(status){
        var label = "Bloqué"
        if(status){
          label = "Actif"
        }
        return label
      },
      getRoleLabel: function(item){
        var label = "ADMIN"
        for (var i = this.roles.length - 1; i >= 0; i--) {
          if(this.roles[i].id == item.role_id){
            label = this.roles[i].name
          }
        }
        return label
      },
      save: function(){
        var self = this
        this.loading = true
        var url = ''
        if (this.index > -1) { 
          url = '/users/'+this.user.id
          this.$axios.put(url+"?data="+encodeURIComponent(JSON.stringify(self.user)))
          .then(response => {
            response.data.role = JSON.parse(response.data.role)
            Object.assign(self.rows[self.index], response.data)
            self.dialog = false
            self.loading = false
            self.reset()
          })
        }else{
          url = '/users'
          
          this.$axios.post(url, self.user)
          .then(response => {
            self.rows.push(response.data);
            self.dialog = false
            self.loading = false
            self.reset()
          })
        }
      },
      edit: function(item){
        this.index = this.rows.indexOf(item)
        this.user = Object.assign({}, item)
        this.form_title = "Editer Utilisateur : "+this.user.full_name
        this.dialog = true
      },
      getRoles: function(){
        var self = this;
        this.$axios.get('/roles')
          .then(function (response) {
            self.roles = response.data
          })
      },
      close: function(){
        this.dialog = false
        this.reset()
        this.loading = false
      },
      reset: function(){
        this.user = {
          username: '', 
          password: '', 
          full_name: '', 
          phone: '', 
          email: '', 
          business_id: 0, 
          role_id: 1,
          status:true,
        }
        this.index = -1
        this.form_title = 'Nouvel Utilisateur'
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.reset()
        })
      },
  },
  mounted(){
    this.load()
    this.getRoles()
  },
  data: () => ({
      search: '',
      status: [{name: "Actif", id: true}, {name: 'Bloqué', id: false}],
      index: -1,
      loading: false,
      form_title: "Nouvel Utilisateur",
      items: [
        {
          text: 'Utilisateurs',
          disabled: false,
          href: '/settings',
        }
      ],
      fluid: true,
      enable: false,
      headers: [
          {
            text: 'NOM',
            align: 'start',
            sortable: false,
            value: 'full_name',
          },
          { text: 'NOM D\'UTILISATEUR', value: 'username' },
          { text: 'TELEPHONE', value: 'phone' },
          { text: 'E-MAIL', value: 'email' },
          { text: 'ROLE', value: 'role.name' },
          { text: 'STATUT', value: 'status' },
          { text: 'ACTIONS', value: 'actions' },
        ],
        rows: [],
        roles: [],
        dialog:'',
        dialogDelete: false,
        user: {
          username: '', 
          password: '', 
          full_name: '', 
          phone: '', 
          email: '', 
          business_id: 0, 
          role_id: 1,
          status: true,
        }
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  .text-right{
    text-align:right;
  }

  .theme--dark.v-btn.v-btn--has-bg{
    background-color:#F68D3A !important;
  }

  .settingscontainer{
    margin-top:10px!important;
  }
  .v-application a{
    color:black!important;
  }
  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  th.text-start, td.text-start{
    text-align:center!important;
  }
  th.text-start:first-child, td.text-start:first-child{
    text-align:left!important;
  }
  th.text-start:last-child, td.text-start:last-child{
    text-align:right!important;
  }
  .class_green{
   background:#4caf50 !important;
   color:white!important;
  }
  .class_red, .confirm_delete_button{
   background:#ff5252 !important;
   color:white!important;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .text-center{
    text-align:center;
  }

  .tablesearch .v-input__slot:before{
    display:none;
  }

  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  .edit_button{
    font-size: 16px;
    padding: 5px;
    background: #003f74 !important;
    color: white!important;
    border-radius: 4px;
  }
  .delete_button{
    background:#ff5252 !important;
    color: white!important;
    border-radius: 4px;
    font-size: 16px;
    padding: 5px;
  }
  .v-application .justify-center{
    justify-content: right!important;
  }
  .v-application .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:19px;
  }
  .v-application .v-dialog .v-btn{
    width:80px!important;
    margin-right:5px;
    margin-top:-5px!important;
  }
  .v-dialog .v-card__title{
    background:#f3f3f3;
    border-bottom:1px solid #ddd;
  }
  .v-btn.close_modal_button{
    background-color:#1976d2!important;
    color:white!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
  }
  .v-dialog .v-card__actions{
    padding-bottom: 15px!important;
    padding-top: 20px!important;
    background: #f3f3f3;
    border-top: 1px solid #ddd;
  }
  .color-red{
    color:red!important;
  }
  .has_errors{
    padding-top:0px;
    margin-top:0px;
  }
  .v-card__actions .v-progress-circular{
    margin-top:1px !important;
  }
  .v-card__actions .v-progress-circular circle{
    color:white!important;
  }
</style>